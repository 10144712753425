import { GetFacilities, getCampaigns } from 'helpers/coreApi';
import { getFooterMenu } from 'helpers/umbracoApi';
import React from 'react';
import { CampaignsDto, FacilityDto } from 'types/generic';
import { MainMenu } from 'types/umbraco';
import Content from './content/content';
import Footer from './footer/footer';
import Header from './header/header';
import styles from './layout.module.css';

export interface SharedPageProps {
  facilities?: FacilityDto[];
  footerMenu?: MainMenu;
  headerMenu?: MainMenu;
  campaigns?: CampaignsDto[];
}

interface LayoutProps extends SharedPageProps {
  children?: React.ReactNode;
}

export function Layout(layoutProps: LayoutProps): JSX.Element {
  const { children, ...globalProps } = layoutProps;
  const isStaging = process.env.NEXT_PUBLIC_ENV === 'staging';
  return (
    <>
      <div className={`${styles.container} ${isStaging && styles.devBorder}`}>
        <Header {...globalProps} />
        <Content>{children}</Content>
        <Footer {...globalProps} />
      </div>
    </>
  );
}

export async function getSharedPageProps(): Promise<
  Record<string, SharedPageProps>
> {
  const facilities = await GetFacilities();
  const footerMenu = await getFooterMenu();


  const headerMenu = {
    items: [
      { name: 'Vanliga frågor', path: '/vanliga-fragor' },
      { name: 'Anläggningar', path: '/anlaggningar/', children: facilities },
      { name: 'Om oss', path: '/om-mitt-lager' },
      { name: 'Kontakt', path: '/kontakt' },
      { name: 'Tips & Råd', path: '/tips-rad'}
    ],
  };

  const campaigns = await getCampaigns();

  // wrap in object property so it's easier to add.
  // now you can just do:
  // const whatEver = await getSharedPageProps();
  // props: {
  //    ...whatEver,
  // },
  return {
    sharedPageProps: {
      facilities,
      footerMenu,
      headerMenu,
      campaigns
    },
  };
}
